import React, { useEffect, useMemo } from 'react';
import { Link, graphql } from 'gatsby';
import { useFlexSearch } from 'react-use-flexsearch';
import LayoutConsumer from '../components/layout-context';
import Seo from '../components/seo';

function SearchPage({
  data: {
    localSearchBlog,
  },
}) {
  // console.log(localSearchPages);
  const { index, store } = localSearchBlog;
  const [showIndex, setShowIndex] = React.useState(0);
  const postCount = 9;
  const [searchQuery, setSearchQuery] = React.useState('');
  const results = useFlexSearch(searchQuery, index, store);
  const posts = searchQuery && searchQuery.length > 2 ? results : [];

  const showMore = useMemo(
    () => posts.length > (showIndex + 1) * postCount,
    [posts.length, showIndex],
  );

  useEffect(() => {
    setShowIndex(0);
    if (typeof window !== 'undefined') {
      if (window.history && searchQuery) {
        window.history.replaceState({}, '', `/search?s=${searchQuery}`);
      } else {
        window.history.replaceState({}, '', '/search');
      }
    }
  }, [searchQuery]);

  useEffect(() => {
    if (typeof window !== 'undefined') {
      const { search } = window.location;
      const query = new URLSearchParams(search).get('s');
      setSearchQuery(query || '');
    }
  }, []);

  return (
    <LayoutConsumer>
      {({ set }) => {
        set({ pageTitle: 'Shopify Search', fullWidth: true });
        return (
          <>
            <Seo
              title="Shopify Search"
              description=""
              meta={[{
                name: 'robots',
                content: 'noindex,nofollow',
              }]}
            />
            <div className="px-4 lg:px-16 flex flex-col py-20 lg:py-36 lg:pb-40 min-h-[80vh]">
              <form
                action="/search"
                onSubmit={(e) => e.preventDefault()}
                method="get"
                autoComplete="off"
              >
                <label
                  htmlFor="search"
                >
                  Suchen
                </label>
                <input
                  value={searchQuery}
                  onInput={(e) => setSearchQuery(e.target.value)}
                  type="text"
                  id="search"
                  // eslint-disable-next-line jsx-a11y/no-autofocus
                  autoFocus
                  name="s"
                  className="text-black border-none bg-white text-3xl md:text-4xl !outline-none max-w-full w-full mt-4"
                />
                {/* <span className="opacity-50 mt-4 block">Die Mindestlänge beträgt 3</span> */}
              </form>
              <div className="mt-[60px] lg:mt-20 grid grid-cols-1 xl:grid-cols-3 md:grid-cols-2 gap-2 md:gap-4">
                {posts.slice(0, ((showIndex + 1) * postCount)).map((post) => {
                  const title = post.name || post.slug;
                  const image = post.hero;
                  return (
                    <Link
                      to={`/${post.full_slug}`}
                      className="mx-auto aspect-[134/85] w-full relative group overflow-hidden"
                      key={title}
                    >
                      <article
                        itemScope
                        itemType="http://schema.org/Article"
                        className="bg-slate-100 dark:bg-latori-green-dark shadow-lg h-full"
                      >
                        {image && (
                        <img
                          alt={title}
                          src={image}
                          loading="lazy"
                          className="object-cover w-full h-full absolute top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%]"
                        />
                        )}
                        <div className="bg-latori-green-dark opacity-90 w-full h-full absolute bottom-0 left-0 transition-[height] group-hover:h-[0%]" />
                        <div className="w-full h-full absolute top-0 left-0 flex flex-col justify-center items-start px-4 group-hover:opacity-0 transition-opacity text-white">
                          <h3 className="text-2xl" itemProp="headline">
                            {title}
                          </h3>
                        </div>
                      </article>
                    </Link>
                  );
                })}
              </div>
              {showMore && (
                <div className="flex justify-center items-center">
                  <button
                    type="button"
                    className="button-primary inline-block mt-12"
                    onClick={() => {
                      setShowIndex(showIndex + 1);
                    }}
                  >
                    MEHR
                  </button>
                </div>
              )}
            </div>
          </>
        );
      }}
    </LayoutConsumer>
  );
}

export default SearchPage;

export const pageQuery = graphql`
  query {
    localSearchBlog {
      index
      store
    }
  }
`;
